import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  SubTitle,
  Button,
  Span,
  Textarea,
} from "../../../StyleComponents/styles"
import { Table } from "../../../StyleComponents/pagesStyle"

const html2canvas = (() => {
  if (typeof window !== "undefined") {
    return require("html2canvas")
  }
})()

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "CSV to Tabel",
    link: "/csv-to-tabel/",
  },
]
const seeAlsoArray = [
  "/json-to-csv-conversion",
  "/csv-to-json-converter",
  "/csv-to-json-converter",
  "/csv-to-sql-conversion",
  "/text-to-csv-conversion",
  "/json-to-xml",
]
function CSVtoTabel(props) {
  let [inputCsv, setinputCsv] = useState('Id,UserName\n1,Roy\n2,"Mike\n3,Jamal')
  let resultTabel = useRef("")
  let downloadBtn = useRef("")

  function inputCsvC(e) {
    setinputCsv(e.target.value)
  }

  function convertCSV() {
    if (inputCsv !== "") {
      var textToConvert = inputCsv
      var splitLines = textToConvert.split("\n")

      for (var i = 0; i < splitLines.length; i++) {
        var tr = document.createElement("tr", null)
        var splitFinal = splitLines[i].match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)
        resultTabel.current.appendChild(tr)
        for (var j = 0; j < splitFinal.length; j++) {
          var td = document.createElement("td")
          td.textContent = splitFinal[j]
          tr.appendChild(td)
        }
      }
      let domCapture = document.getElementById("all")

      // if (typeof window !== "undefined") {
      // import("html2canvas").then(html2canvas => {
      html2canvas(domCapture, {
        scrollY: -window.scrollY,
      }).then(function (canvas) {
        //   domCapture.saveAs(blob, "yourwebsite_screenshot.png");
        downloadBtn.current.download = "image.png"
        downloadBtn.current.href = canvas.toDataURL()
      })
      //   });
      // }
    }
  }
  function clearTabel() {
    resultTabel.current.textContent = ""
  }
  function clearTabelAndInput() {
    setinputCsv("")
    resultTabel.current.textContent = ""
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="CSV To Tabel, CSV To Tabel Conversion"
        description="CSV To Tabel, CSV To Tabel Conversion, Simple Converter to a tabel easy To read and understand, CSV to Tabel will convert a CSV to a table make it simple to read and download the tabel as image png, step by step."
        keywords={[
          "CSV To Tabel Converter,CSV To Tabel translator,CSV To Tabel, CSV To Tabel translator,convert CSV To Tabel, CSV To Tabel convertion,csv to tabel image, CSV To Tabel, CSV To Tabel, CSV To Tabel converter and download.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Convert CSV to Tabel</Title>

        <SubTitle>CSV to Tabel and Download Tabel as png</SubTitle>

        <Span>Paste Your CSV Here:</Span>
        <Textarea value={inputCsv} onChange={inputCsvC}></Textarea>
        <div className="mb-4">
          <Button
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={convertCSV}
          >
            Convert
          </Button>
          <Button
            borderColor="#ea4336"
            color="#ea4336"
            hoverBorderColor="#ea4336"
            hoverBackgroundColor="#ea4336"
            hoverColor="white"
            onClick={clearTabelAndInput}
          >
            Clear
          </Button>
        </div>
        <div id="all">
          <Table ref={resultTabel}></Table>
          <br />
        </div>
        <br />
        <a href="#" download="image.png" ref={downloadBtn} onClick={clearTabel}>
          Download Tabel as image
        </a>
        <br />
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default CSVtoTabel
